<template>
	<div>
		<p>登录中</p>
	</div>
</template>

<script>
import {httpPost,httpGet} from "@/utils/http"
import global from "@/common/global"
export default{
	data(){
		return {
			suiteTicket:"",
			suiteAccessToken:"",
			code:"",
			//签名-登录用
			loginSign:""
		}
	},
	created() {
		let tempCode = this.getUrlParam("code")
		//获取code
        if(!tempCode){
			//回调连接
			let localUrl = encodeURIComponent(window.location.href) //回调url-本页
			//state
			let state = (new Date().getTime())+"corpbid"
			let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+global.suiteId+"&redirect_uri="+localUrl
            +"&response_type=code"
            +"&scope=snsapi_userinfo"
            +"&state="+state
            +"#wechat_redirect"
			window.location.href = url
		}else{
			let that = this
			//获取suite access token
			this.code = tempCode
			//从后台获取suite_access_token
			let url = global.gateway+"/ewbid/workweixin/getSuiteAccessToken"
			let params = {
				"suiteId":global.suiteId
			}
			httpGet(url,params).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					that.suiteAccessToken = res.data.suite_access_token
					that.getUserInfo()
				}
			}).catch(error=>{
				console.log(error)
			})
		}
	},
	methods:{
		//获取用户信息
		getUserInfo:function(){
			let that = this
			httpGet("/workweixinauth/cgi-bin/service/getuserinfo3rd",{
				"suite_access_token":this.suiteAccessToken,
				"code":this.code
			}).then(res=>{
				if(res.status=="200"&&res.data.errcode=="0"){
					//处理用户信息
					that.processUserInfo(res.data)
				}
			}).catch(error=>{
				alert("error:"+JSON.stringify(error))
			})
		},
		//处理用户
		processUserInfo:function(wwUserInfo){
			//先清空缓存
			localStorage.removeItem('workweixin_corp_bid_user');
			localStorage.removeItem('corp_bid_sysdepart');
			localStorage.removeItem('corp_bid_ew_store');
			localStorage.removeItem('corp_bid_customer');
			localStorage.removeItem('corp_bid_org_user_map');
			localStorage.removeItem('corp_bid_org_user_map');

			//读取当前用户信息
			let userData = {}
			//企业微信id
			userData.CorpId = wwUserInfo.CorpId
			//用户
			userData.UserId = wwUserInfo.UserId
			//open_userid
			userData.open_userid = wwUserInfo.open_userid
			//user_ticket
			userData.user_ticket = wwUserInfo.user_ticket
			//sutie_id
			userData.suite_id = global.suiteId
			//存入缓存---workweixin_user
			localStorage.setItem("workweixin_corp_bid_user",JSON.stringify(userData))
			// this.$ls.set("workweixin_corp_bid_user",userData)
			//后台查询
			httpPost(global.gateway+'/ewbid/workweixin/workweixinBidLogin',userData).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					let existsSysDepart = false
					let existsEwStore = false
					let existsCustomer = false
					let existsOrgUserMap = false
					//sysdepart
					if(res.data.sysdepart){
						existsSysDepart = true
						//写缓存
            /**TODO 未用到 */
						localStorage.setItem("corp_bid_sysdepart",JSON.stringify(res.data.sysdepart))
					}
					//ewStoreExternal
					if(res.data.ewStoreExternal){
						existsEwStore = true
						//写缓存
						localStorage.setItem("corp_bid_ew_store",JSON.stringify(res.data.ewStoreExternal))
					}
					//customer
					if(res.data.customer){
						existsCustomer = true
						//写缓存
						localStorage.setItem("corp_bid_customer",JSON.stringify(res.data.customer))
					}
					//orgUserMap
					if(res.data.orgUserMaps&&res.data.orgUserMaps.length>0){
						existsOrgUserMap = true
						//查找并写入缓存
						for(let ii=0;ii<res.data.orgUserMaps.length;ii++){
							let oo = res.data.orgUserMaps[ii]
							//当前应用
							if(oo.suiteId==global.suiteId){
								localStorage.setItem("corp_bid_org_user_map",JSON.stringify(oo))
							}
						}
					}else{
						//无权限页面
						this.$router.replace({name:"noauth"})
					}
					//登录信息正常
					if(existsSysDepart&&existsEwStore&&existsCustomer&&existsOrgUserMap){
						//统一社会信用代码
						let uscc = ''
						if(res.data.ewStoreExternal.uscc)
							uscc = res.data.ewStoreExternal.uscc
						//联系人
						let linkMan = ''
						if(res.data.ewStoreExternal.linkMan)
							linkMan = res.data.ewStoreExternal.linkMan
						//联系电话
						let linkPhone = ''
						if(res.data.ewStoreExternal.linkPhone)
							linkPhone = res.data.ewStoreExternal.linkPhone
						//联系地址
						let linkAddr = ''
						if(res.data.ewStoreExternal.linkAddr)
							linkAddr = res.data.ewStoreExternal.linkAddr
						//判断是否需要完善信息
						if(uscc==""||linkMan==""||linkPhone==""||linkAddr==""){
							//跳转完善页面
							this.$router.replace({name:"register"})
						}else{
							//跳转home页面
							this.$router.replace({name:"home"})
						}
					}else{
						//无权限页面
						this.$router.replace({name:"noauth"})
					}
				}
				else if(res.status=="200"&&res.data.retCode!=''){
					if(res.data.errMsg){
						alert(res.data.errMsg)
						return
					}else{
						alert("登录异常！")
						return
					}
				}
			})
		},
		//解析url
		getUrlParam: function (name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
			var r = window.location.search.substr(1).match(reg)
			if (r != null) return unescape(r[2])
			return null
		},
	}
}
</script>

<style>
</style>
